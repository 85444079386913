.right {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    height: calc(100vh - var(--layout-header-height));
    overflow: hidden;
    overflow-y: auto;
    padding-right: 10px;
}
