.listHeader {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;

    .actions {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }
}

.delete {
    cursor: pointer;
}
