.page {
    width: 100%;
    padding: 10px 20px;
    position: relative;

    .success {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            display: flex;
            font-size: 20px;
            margin-bottom: 20px;
        }

        .actions {
            display: flex;
            gap: 12px;
        }
    }
}
