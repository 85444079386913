.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;

    .image {
        margin: 0 auto;
        img {
            width: 300px;
        }
    }

    .createNew {
        font-size: 20px;
        font-weight: 500;
    }
}
