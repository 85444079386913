.app {
    .logo {
        display: flex;
        height: var(--layout-header-height);
        justify-content: center;
        align-items: center;
        margin-left: 15px;

        img {
            height: 35px;
            display: flex;
        }
    }

    .header {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--layout-background-color);

        .left {
            display: flex;

            .trigger {
                margin-left: 10px;
                font-size: 20px;
            }
        }

        .right {
            display: flex;

            .avatar {
                cursor: pointer;
                margin-right: 10px;
            }
        }
    }
}
