.left {
    display: flex;
    flex-direction: column;
    width: var(--layout-right-column-width);
    height: calc(100vh - (var(--layout-header-height) + (var(--layout-padding) * 2)));
    background-color: var(--layout-right-column-background-color);
    padding: var(--layout-right-column-padding);
    border-radius: var(--layout-right-column-border-radius);
    margin: var(--layout-padding);
    overflow: hidden;

    .header {
        padding-bottom: 20px;
        position: relative;
        display: flex;
        flex-direction: column;

        .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .actions {
                display: flex;
                flex-direction: row;
                gap: 12px;
            }

            h2 {
                font-size: 18px;
            }
        }
    }

    .items {
        overflow: hidden;
        overflow-y: scroll;
        margin-left: -5px;
        margin-right: -5px;
        padding-left: 5px;
        padding-right: 5px;
        height: calc(100vh - 220px);

        .listItem {
            display: flex;
            flex-direction: row;

            .item {
                width: 100%;
            }

            .orderIcons {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                margin-left: 8px;

                .orderIconWrapper {
                    min-width: 15px;

                    .orderIcon {
                        font-size: 18px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        align-items: center;
    }
}
