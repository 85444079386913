@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

@primary-color: #2e9e97; // primary color for all components
@link-color: #2e9e97; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 15px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

@height-base: 40px;
@height-lg: 45px;
@height-sm: 30px;

@border-radius-base: 6px;

@layout-body-background: #f0f2f5;
@layout-header-background: #fff;
@layout-header-height: 55px;
@menu-highlight-color: #fff;
@menu-inline-toplevel-item-height: 50px;
@menu-item-active-bg: @primary-color;
@menu-item-hover-bg: @primary-color;
@menu-item-vertical-margin: 0;
@menu-item-boundary-margin: 0;
@menu-icon-size-lg: 20px;

.@{menu-prefix-cls} {
    &-light {
        .@{menu-prefix-cls}-item:hover,
        .@{menu-prefix-cls}-item-active,
        .@{menu-prefix-cls}:not(.@{menu-prefix-cls}-inline) .@{menu-prefix-cls}-submenu-open,
        .@{menu-prefix-cls}-submenu-active,
        .@{menu-prefix-cls}-submenu-title:hover {
            color: @menu-highlight-color;
            background-color: @primary-color;
        }
    }
}

:root {
    --background-color: #fff;
    --divider-color: #f0f0f0;
    --primary-color: @primary-color;
    --box-shadow-cards: 0 3px 5px 2px rgba(0, 0, 0, 0.05);
    --active-color: var(--background-color);
    --border-color-base: @border-color-base;
    --border-radius-base: @border-radius-base;
    --input-outline-offset: @input-outline-offset;
    --outline-blur-size: @outline-blur-size;
    --outline-width: @outline-width;
    --outline-focused-color: fade(@primary-color, @outline-fade);
    --layout-header-height: @layout-header-height;
    --layout-background-color: @layout-body-background;
    --layout-right-column-width: 450px;
    --layout-right-column-padding: 10px;
    --layout-right-column-border-radius: 10px;
    --layout-right-column-background-color: var(--background-color);
    --layout-footer-background-color: var(--background-color);
    --layout-padding: 10px;
    --error-color-deprecated-bg: color(~`colorPalette("@{error-color}", 2) `);
    --input-border-color: @input-border-color;
    --input-hover-border-color: @input-hover-border-color;
    --input-padding-vertical-base: @input-padding-vertical-base;
    --input-padding-horizontal-base: @input-padding-horizontal-base;
    --input-box-shadow-base: @input-outline-offset @outline-blur-size @outline-width
        @primary-color-outline;
    --font-size-base: @font-size-base;
}

.ant-input-password {
    padding: 0;

    &.ant-input-affix-wrapper {
        .ant-input {
            padding: 7.2px 35px 7.2px 11px;
        }

        .ant-input-suffix {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.header-without-padding > .ant-tabs-nav .ant-tabs-tab {
    padding: 0;
}

.header-without-padding .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px;
}
