.form {
    .tabs {
        .tabWithoutErrors {
            padding: 7.5px 16px;
        }

        .tabWithErrors {
            @extend .tabWithoutErrors;
            background-color: var(--error-color-deprecated-bg);
        }
    }
}
