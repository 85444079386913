.card {
    position: relative;

    &:hover {
        .delete {
            display: block;
        }
    }

    .delete {
        display: none;
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 5px;
        cursor: pointer;
    }
}
